import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import LayoutSplit from '@/components/ui/LayoutSplit';
import Paginator from '@/components/ui/Paginator';
import { DownloadResults } from '@/components/content/MarketingListTool/DownloadResults';
import { InfopankPersonSearchResponseDto } from '@/apiTypes/types';
import { getInternalLink } from '@/lib/helpers/getInternalLink';
import { MARKETING_MAX_ITEMS } from '@/lib/constants';
import { Locale } from '@/lib/enums/locale.enum';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import { User } from '@/components/auth/AuthProvider';

interface IProps {
  data: InfopankPersonSearchResponseDto;
  setCurrentPage: (value: number) => void;
  filter: IMarketingListToolFilter;
  user: User | null;
  isTotalLoaded: boolean;
}

export const PersonResults = (props: IProps) => {
  const total = props.data.meta.total < MARKETING_MAX_ITEMS ? props.data.meta.total : MARKETING_MAX_ITEMS;
  const numberOfPages = Math.ceil(total / props.data.meta.pageSize);
  const { t, i18n } = useTranslation();

  return (
    <LayoutSplit layout="filter">
      <div>
        <table className="wrappable">
          <thead>
            <tr>
              <th>{t('person.table.name')}</th>
              <th className="align-right">{t('person.table.birthday')}</th>
            </tr>
          </thead>
          <tbody>
            {(props.data.items || []).map((p, i) => {
              return (
                <tr key={i}>
                  <td>
                    <Link
                      target="_blank"
                      href={getInternalLink('Person', p.id, i18n.language as Locale)}
                      draggable={false}
                      prefetch={false}
                    >
                      {p.fullName}
                    </Link>
                  </td>
                  <td
                    data-label={t('person.table.birthday')}
                    className="align-right label-secondary"
                  >
                    {p.dateOfBirth}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Paginator
          numberOfPages={numberOfPages}
          currentPage={props.data.meta.pageNumber + 1}
          onPageChange={({ selected }) => props.setCurrentPage(selected)}
        />
      </div>
      <DownloadResults
        total={props.data.meta.total}
        filter={props.filter}
        type={'Person'}
        user={props.user}
        isTotalLoaded={props.isTotalLoaded}
      />
    </LayoutSplit>
  );
};
