'use client';

import * as React from 'react';
import Button from '@/components/ui/Button';
import useOfferOverlay from '@/lib/hooks/useOfferOverlay';

export interface IButtonAskOfferProps {
  title: string;
  analyticsId?: string;
}

const ButtonAskOffer: React.FunctionComponent<IButtonAskOfferProps> = (props) => {
  const { open: openOfferForm } = useOfferOverlay();
  return (
    <Button
      title={props.title}
      onClick={() => openOfferForm({ analyticsId: props.analyticsId })}
      size="large"
    />
  );
};

ButtonAskOffer.displayName = 'ButtonAskOffer';

export default ButtonAskOffer;
