import { DateTime, Duration } from 'luxon';
import slugify from 'slugify';
import { convert } from 'html-to-text';

export const formatDuration = (seconds: number) => Duration.fromObject({ seconds }).toFormat('mm:ss');
export const aripaev = 'aripaev';
export const infopank = 'infopank';

export const slugifyString = (val: string): string =>
  slugify(val, {
    replacement: '-',
    lower: true,
    remove: /[?!.()*@:;"'+]/g,
  });

export const defaultLocale = () => process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

type DateFormat = 'dd.LL.yyyy' | 'yyyy-LL-dd';
export const formatDate = (dateStr: string | undefined | null, format?: DateFormat): string | null => {
  if (!dateStr) {
    return null;
  }
  if (format) {
    const datetime = DateTime.fromISO(dateStr);
    return datetime.isValid ? datetime.toFormat(format) : null;
  }
  return new Intl.DateTimeFormat(defaultLocale(), {
    dateStyle: 'short',
  }).format(new Date(dateStr));
};

export const htmlToText = (value: string): string =>
  convert(value, {
    selectors: [
      {
        selector: 'a',
        options: { ignoreHref: true },
      },
      {
        selector: 'img',
        format: 'skip',
      },
      {
        selector: 'p',
        format: 'inlineSurround',
        options: {
          prefix: '',
          suffix: ' ',
        },
      },
    ],
    wordwrap: false,
  });

/**
 * Remove non-integers
 * Remove all "," and "." except first occurrence
 * Replace "," with "."
 * -- --
 * input: "221,..awdadwa..44443dawda0"
 * output: "221.444430"
 * */
export const sanitizeFloatInput = (input: string) => {
  return input.replace(/[^0-9.,]|(?<=[.,].*?)[.,]/g, '').replace(',', '.');
};
