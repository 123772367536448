import { User } from '@/components/auth/AuthProvider';
import { getCookie } from '@/lib/helpers/cookie';
import { CookieKey } from '@/lib/enums/cookieKey.enum';
import { getUserDataHeaders } from '@/lib/helpers/getUserDataHeaders';

export const downloadFile = async (
  href: string,
  user: User | null,
  method = 'GET',
  body?: string | null,
  noDomain = false,
  onlyDownload = false,
) => {
  const orderCode = getCookie(CookieKey.orderCode);

  const response = await fetch(`/api/download?url=${encodeURIComponent(href)}&noDomain=${noDomain}`, {
    body: body ?? undefined,
    method,
    headers: getUserDataHeaders(user, orderCode),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch file');
  }

  const blob = await response.blob();
  let fileName = 'company-pdf';
  const contentDisposition = response.headers.get('Content-Disposition');
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
    if (fileNameMatch && fileNameMatch[1]) {
      fileName = decodeURIComponent(fileNameMatch[1].replace(/['"]/g, ''));
    }
  }

  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = downloadUrl;
  a.download = fileName;
  a.click();

  if (!onlyDownload) {
    window.open(downloadUrl, '_blank');
  }

  URL.revokeObjectURL(downloadUrl);
};
