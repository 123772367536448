'use client';

import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import LayoutForm from '@/components/ui/LayoutForm';
import FormRow from '@/components/ui/FormRow';
import ControlInput from '@/components/ui/ControlInput';
import ControlTextarea from '@/components/ui/ControlTextarea';
import Buttons from '@/components/ui/Buttons';
import ButtonsPrimary from '@/components/ui/Buttons/Primary';
import Button from '@/components/ui/Button';
import ButtonsSecondary from '@/components/ui/Buttons/Secondary';
import { accept } from '@/components/content/AddDebtForm/helper';
import UploadFile from '@/components/forms/UploadFile';
import Callout from '@/components/ui/Callout';
import TurnstileChallenge from '@/components/turnstile/TurnstileChallenge';

export interface IApplicationFormProps {
  onSubmit: () => void;
  onCancel: () => void;
}

interface IApplicationForm {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  message: string;
  files: File[];
}

type IFormErrors = Partial<Record<keyof IApplicationForm, string>>;
type formDataType = string | Blob;

const ApplicationForm: React.FunctionComponent<IApplicationFormProps> = (props) => {
  const initialState: IApplicationForm = {
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    message: '',
    files: [],
  };
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [form, setForm] = useState<IApplicationForm>(initialState);
  const [errors, setErrors] = useState<IFormErrors>({});
  const [formHasError, setFormHasError] = React.useState<boolean>(false);
  const [formFocused, setFormFocused] = React.useState(false);
  const [challengeResponse, setChallengeResponse] = React.useState<string | undefined>(undefined);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(t('components.askOffer.required')),
    lastname: Yup.string().required(t('components.askOffer.required')),
    phone: Yup.string()
      .nullable()
      .notRequired()
      .test('is-valid-phone', t('components.askOffer.validation.wrongPhone'), (value) => {
        if (!value) return true;
        return /^\d+$/.test(value) && value.length >= 7;
      }),
    email: Yup.string().email(t('components.askOffer.validation.wrongEmail')).required(t('components.askOffer.required')),
    message: Yup.string(),
  });

  const onChange = (value: string | File[] | null, id: keyof IApplicationForm) => {
    if (!formFocused) {
      setFormFocused(true);
    }
    setForm((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const setFiles = (files: File[]) => {
    setForm((prevForm) => ({
      ...prevForm,
      files: files,
    }));
  };

  const setFormInitialState = () => {
    setForm(initialState);
    setErrors({});
    setFormHasError(false);
  };

  const validate = async (formData: IApplicationForm): Promise<IFormErrors> => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      return {};
    } catch (validationErrors) {
      const formattedErrors: IFormErrors = {};
      if (validationErrors instanceof Yup.ValidationError) {
        validationErrors.inner.forEach((error) => {
          if (error.path) {
            formattedErrors[error.path as keyof IFormErrors] = error.message;
          }
        });
      }
      return formattedErrors;
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const validationErrors = await validate(form);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
    } else {
      setErrors({});
      const asFormData = new FormData();
      Object.entries(form).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => asFormData.append(key, v as formDataType));
        } else {
          asFormData.append(key, value as formDataType);
        }
      });
      asFormData.append('challengeResponse', challengeResponse as formDataType);

      try {
        const response = await fetch('/api/send-candidate-form', {
          method: 'POST',
          body: asFormData,
        });

        const body = (await response.json()) as { error?: Record<string, string> } | null;
        if (body && body.error) {
          setFormHasError(true);
          return;
        }
        setFormInitialState();
        props.onSubmit();
      } catch (error) {
        setFormHasError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      {formHasError && (
        <Callout
          intent="danger"
          text={t('components.askOffer.error')}
        />
      )}
      {formFocused && (
        <TurnstileChallenge
          id="candidate-form"
          action="candidate-form"
          onToken={setChallengeResponse}
        />
      )}
      <LayoutForm>
        <FormRow
          label={t('components.applicationForm.firstname')}
          required={t('components.applicationForm.required')}
          for="message-firstname"
          size="narrow"
          hasError={errors.firstname}
        >
          <ControlInput
            id="message-firstname"
            disabled={loading}
            hasError={!!errors.firstname}
            onChange={(e) => onChange(e.target.value, 'firstname')}
          />
        </FormRow>
        <FormRow
          label={t('components.applicationForm.surname')}
          required={t('components.applicationForm.required')}
          for="message-lastname"
          size="narrow"
          hasError={errors.lastname}
        >
          <ControlInput
            id="message-lastname"
            disabled={loading}
            hasError={!!errors.lastname}
            onChange={(e) => onChange(e.target.value, 'lastname')}
          />
        </FormRow>
        <FormRow
          label={t('components.applicationForm.phone')}
          for="message-phone"
          size="narrow"
          hasError={errors.phone}
        >
          <ControlInput
            id="message-phone"
            disabled={loading}
            hasError={!!errors.phone}
            onChange={(e) => onChange(e.target.value, 'phone')}
          />
        </FormRow>
        <FormRow
          label={t('components.applicationForm.email')}
          required={t('components.applicationForm.required')}
          for="message-email"
          size="narrow"
          hasError={errors.email}
        >
          <ControlInput
            id="message-email"
            disabled={loading}
            hasError={!!errors.email}
            onChange={(e) => onChange(e.target.value, 'email')}
          />
        </FormRow>
        <FormRow
          for="message-message"
          label={t('components.applicationForm.message')}
          hasError={errors.message}
        >
          <ControlTextarea
            id="messagemessage"
            minRows={5}
            maxRows={5}
            disabled={loading}
            value={form.message}
            hasError={!!errors.message}
            onChange={(e) => onChange(e.target.value, 'message')}
          />
        </FormRow>
        <FormRow
          for="message-files"
          label={t('components.applicationForm.addCv')}
          hasError={errors.files}
        >
          <div>
            <UploadFile
              loading={loading}
              accept={accept}
              files={form.files}
              setFiles={setFiles}
            />
          </div>
        </FormRow>
      </LayoutForm>
      <Buttons>
        <ButtonsSecondary>
          <Button
            title={t('components.applicationForm.cancel')}
            iconLeft="close"
            theme="link"
            size="large"
            onClick={props.onCancel}
          />
        </ButtonsSecondary>
        <ButtonsPrimary>
          <Button
            title={t('components.applicationForm.send')}
            theme="primary"
            size="large"
            onClick={() => {
              void onSubmit();
            }}
          />
        </ButtonsPrimary>
      </Buttons>
    </React.Fragment>
  );
};

ApplicationForm.displayName = 'ApplicationForm';

export default ApplicationForm;
