'use client';

import * as React from 'react';
import Link from 'next/link';
import Icon from '@/components/ui/Icon';
import styles from './styles.module.scss';

const SalesArguments: React.FunctionComponent<{
  data: {
    title: string;
    description: React.ReactNode;
    href: string;
    imageUrl: string;
  }[];
}> = (props) => {
  return (
    <ul className={styles['sales-arguments']}>
      {props.data.map((item, itemIndex) => {
        return (
          <li key={itemIndex}>
            <Link
              href={item.href}
              draggable={false}
              prefetch={false}
            >
              <div className={styles['sales-arguments__headline']}>
                <div className={styles['sales-arguments__image']}>
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    width={80}
                    height={80}
                  />
                </div>
                <div className={styles['sales-arguments__title']}>{item.title}</div>
              </div>
              <div className={styles['sales-arguments__description']}>{item.description}</div>
              <div className={styles['sales-arguments__button']}>
                <Icon
                  width={24}
                  height={24}
                  kind="arrow02-right"
                />
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

SalesArguments.displayName = 'SalesArguments';

export default SalesArguments;
