import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Well from '@/components/ui/Well';
import Buttons from '@/components/ui/Buttons';
import Button from '@/components/ui/Button';
import { downloadFiles } from '@/lib/helpers/downloadFiles';
import OverlayAskOffer from '@/components/overlays/AskOffer';
import { IMarketingListToolFilter } from '@/components/content/MarketingListTool/interfaces/marketingListToolFilter.interface';
import LoadingContent from '@/components/ui/LoadingContent';
import { PersonFilterOptions } from '@/components/content/MarketingListTool/interfaces/personFilterOptions.interface';
import useOfferOverlay from '@/lib/hooks/useOfferOverlay';

interface IProps {
  total: number;
  form: IMarketingListToolFilter;
  isPersonFilter: boolean;
  formType?: 'MARKETING' | 'BACKGROUND' | 'CONTACT';
  options: PersonFilterOptions;
  isTotalLoaded: boolean;
  analyticsId?: string;
}

export const UnAuthorizedResults: React.FC<IProps> = ({ total, form, isPersonFilter, formType, options, isTotalLoaded, analyticsId }) => {
  const { t } = useTranslation();
  const { open: openOfferForm } = useOfferOverlay('MARKETING');

  return (
    <div>
      <Well theme="medium">
        {isTotalLoaded ? (
          <p className="text-size-large bold">
            {total} {t('components.search.result')}
          </p>
        ) : (
          <LoadingContent size="small" />
        )}
        <p>{t('components.search.description')}</p>

        <Buttons>
          <Button
            title={t('components.search.sendRequest')}
            theme="primary"
            size="large"
            onClick={() => openOfferForm({ analyticsId })}
          />
          <Button
            title={t('components.search.viewFileExample')}
            iconLeft="document-download"
            theme="default"
            size="large"
            onClick={() => downloadFiles(['/tmp/companySample.xlsx', '/tmp/personSample.xlsx'])}
          />
        </Buttons>
      </Well>
      <OverlayAskOffer
        form={form}
        isPersonFilter={isPersonFilter}
        formType={formType}
        options={options}
      />
    </div>
  );
};
